<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <div class="attach-file-container">
      <section v-if="!form.value.length">
        <span>Nenhum arquivo anexado</span>
      </section>
      <section 
        v-else
        class="file-container"
        :class="{'overflow-x-hidden': !viewAllDocs}"
      >
        <div 
          class="file-tag"
          v-for="(file, index) in form.value.slice(0, fileShowSize)" :key="index"
        >
          <div class="data-file">
            <div class="data-file" @click="openDocsTab(file)">
              <Gallery v-if="Array.isArray(file)" class="file-icon" />
              <Pdf v-else-if="file?.path?.includes('pdf')" class="file-icon" />
              <Png v-else-if="file?.path?.includes('png')" class="file-icon" />
              <Jpg v-else class="file-icon" />
              <p class="file-name">{{ file?.custom_name ?? file[0]?.group_name }}</p>
            </div>
            <div class="close-btn" v-if="canEdit" @click="handleDeleteFiles(index)">
              <Close class="close-icon" />
            </div>
          </div>
        </div>
        <section 
          v-if="form.value.length > fileShowSize && !viewAllDocs"
          class="tag" 
        >
          <span>+ {{( form.value.length - fileShowSize)}}</span>
        </section>
      </section>
      <section 
        v-if="form.value.length > fileShowSize || viewAllDocs"
        class="tags-container overflow-x-hidden"
        >
        <section 
          class="tag" 
          @click="() => viewAllDocs = !viewAllDocs"
        >
          <span  class="eye-container">
            <EyeFill v-if="viewAllDocs" />
            <EyeSlash v-else/>
          </span>
        </section>
      </section>
      <section class="overflow-x-hidden">
        <b-button variant="outline-primary" @click="openModal()" :disabled="!canEdit">
          <File class="icon" />
          Anexar arquivo
        </b-button>
      </section>
    </div>
    <AttachmentFilesModal 
      v-if="attendance.attendance"
      :patientId="attendance?.attendance?.patient?.id"
      @uploadedFiles="uploadFile" 
    />
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    File: () => import('@/assets/icons/file.svg'),
    AttachmentFilesModal: () => import('@/components/General/UploadFilesModal.vue'),
    Pdf: () => import('@/assets/icons/pdf.svg'),
    Jpg: () => import('@/assets/icons/jpg.svg'),
    Png: () => import('@/assets/icons/png.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    EyeSlash: () => import('@/assets/icons/eye-slash.svg'),
    Gallery: () => import('@/assets/icons/gallery.svg')
  },
  data () {
    return {
      fileShowSize: 3,
      viewAllDocs: false,
    }
  },
  created() {
    this.handleScreenWidth()
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.anexarArquivos,
      attendance: state => state.attendance,
    })
  },
  watch: {
    viewAllDocs: function () {
      if (this.viewAllDocs) {
        this.fileShowSize = this.form?.value?.length
        return
      }
      this.handleScreenWidth()
    },
  },
  methods: {
    ...mapActions('attendance/form', ['handleActiveModule', 'updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/anexarArquivos', ['pushFiles', 'removeFile', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    handleScreenWidth() {
      const width = window.innerWidth
      if (width < 1500) {
        this.fileShowSize = Math.floor(width/400)
        return
      }
      this.fileShowSize = Math.floor(width/300)
    },
    openModal() {
      this.$bvModal.show("upload-files-modal")
    },
    uploadFile (data) {
      this.pushFiles(data)
      this.updateMedicalRecord(this.form)
    },
    async handleDeleteFiles (index) {
      await this.deleteFile(
        Array.isArray(this.form.value[index])
        ? this.form.value[index][0].id
        : this.form.value[index].id
      )
      this.removeFile(index)
      await this.updateMedicalRecord(this.form)

      if (this.form.value.length <= this.fileShowSize) {
        this.viewAllDocs = false
      }
    },
    async deleteFile(id) {
      const loading = this.$loading.show()
      try {
        await this.api.deleteAttendanceFile(id)
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async getDocUrl(path){
      const loading = this.$loading.show()
      try {
        const res = await this.api.getDocTemporaryUrl(path)
        window.open(res.data, '_blank')
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async openDocsTab(file) {
      if (Array.isArray(file)) {
        file.forEach(async doc => {
          await this.getDocUrl(doc.path)
        })
        return
      }
      await this.getDocUrl(file.path)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value.forEach((key,index) => {
        this.removeFile(index)
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.attach-file-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > section {
    overflow-y: hidden;
  }

  .overflow-x-hidden {
    overflow-x: hidden;
  }

  button {
    color: var(--blue-600);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .icon {
    stroke: var(--blue-600);
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .fit-content {
    width: fit-content !important;
  }

  .file-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 85%;

    @media (min-width: 1442px) and (max-width: 1740px) {
      width: 80%;
    }

    @media (max-width: 1441px) {
      width: 70%;
    }
    .file-tag {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;
      height: 35px;
      padding: 0 4px;
      background-color: var(--neutral-200);
      border-radius: 16px;
      cursor: pointer;

      .data-file {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .file-icon {
          width: 42px !important;
          height: 42px !important;
          transform: scale(0.5);
        }

        .file-name {
          width: 8ch;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .close-btn {
        background-color: var(--neutral-300);
        border-radius: 100%;

        :hover {
          fill: var(--neutral-600) !important;
        }
      }
      .close-icon {
        height: 20px;
        width: 20px;
        fill: var(--neutral-500);
      }

    }
  }

  .tags-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .tag:nth-child(1) {
      margin-right: 5px;
    }
  }
  .tag {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    height: 35px;
    align-items: center;
    padding: 0 10px;
    border-radius: 16px;
    font-weight: 800;
    color: var(--type-active);
    background-color: var(--neutral-200);
  }

  .eye-container {
    background-color: var(--neutral-200);
    border-radius: 4px;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--neutral-600);
    margin-right: 0px;

    svg {
      width: 22px;
      height: 22px;
      fill: var(--neutral-600);
    }
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}
</style>
