import { render, staticRenderFns } from "./AnexarArquivos.vue?vue&type=template&id=054a3b6e&scoped=true"
import script from "./AnexarArquivos.vue?vue&type=script&lang=js"
export * from "./AnexarArquivos.vue?vue&type=script&lang=js"
import style0 from "./AnexarArquivos.vue?vue&type=style&index=0&id=054a3b6e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "054a3b6e",
  null
  
)

export default component.exports